.franja_narajna_creditos{
	background: url(../img/background_orange.jpg);
    background-position: center;
    background-size: cover;
    padding: 70px 0 30px 0; 
    color:$blanco;
    position:relative;
    font-family: 'Lato', sans-serif;
    span{
    	vertical-align: middle;
    	color:$amarillo;
    	font-size: 18px;
        @media #{$small-only}{
            font-size: 16px;
        }
    }
    .contenido_ventajas{
    	margin-top:30px;
    	.caja_lafin{
	    	position:absolute;
	    	max-width: 275px;
	    }
	    .texto_ventajas{
	    	margin-top: 20px;
	    	text-align: justify;
	    	font-size:15px !important;
            margin-right: 20px;
	    	span{
	    		color:$amarillo;
	    		font-size:15px;
	    	}
	    }
    }
    .creditos_flechas{
        @media #{$small-only}{
            text-align: center;
            img{
                width: 30px;
            }
        }
    }
    .creditos_flechas_descripcion{
        @media #{$small-only}{
            text-align: center;
            font-size: 12px;
        }   
    }
}
.franja_requisitos{
	padding: 70px 0;
	.requisitos_title{
		font-size: 25px;
	     color: $naranja;
	     margin-bottom:40px;
	}
}

.franja_creditos_grupales{
	background: url(../img/background_orange.jpg);
    background-position: center;
    background-size: cover;
    padding: 70px 0;
    color:$blanco;
    position:relative;
    font-family: 'Lato', sans-serif;
    .titulo_creditos_individuales{
    	font-size:20px;
    	font-family: 'Lato', sans-serif;
    }
    .contenido_creditos_individuales{
    	font-size:16px;
    	margin-bottom:30px;
    }
}