.privacidad{
	p{
		text-align: justify;
	}
}
.mainFooter{
	background:url(../img/background_orange.jpg);
	background-position:center;
	background-size:cover;
	padding:100px 0;
	font-family: 'Lato', sans-serif;
	input[type=text]{
		height: 40px;
		box-shadow: none;
		border:0;
	}
	textarea{
		box-shadow: none;
		border:0;	
	}
	input[type=submit]{
		width:100%;
		background: #ffdc00; /* Old browsers */
		background: -moz-linear-gradient(top,  #ffdc00 1%, #ffb400 98%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  #ffdc00 1%,#ffb400 98%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  #ffdc00 1%,#ffb400 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdc00', endColorstr='#ffb400',GradientType=0 ); /* IE6-9 */
		color:$naranja;
	}
	.footer_column_der{
		color:$blanco;
		text-align: justify;
		font-size:14px;
		.top_sucursal{
			font-size:16px;
		}
		.text_negro{
			color:$negro;
			font-weight: bold;
			font-size:16px;
		}
		a{
			color:$blanco;
			&:hover{
				font-weight: bold;
				text-decoration:underline;
			}
		}
		@media #{$small-only}{
			float:left !important;
		}
	}
}

.alert-box{
	display:inline-block;
	width: 100%;
	padding:5px;
	margin-bottom: 20px;
}
.warning {
	background-color:#f04124;
	color:#fff;
}
.success{
	background-color:#43AC6A;
	color:#fff;
}
.round{
	border-radius: 10px;
}
.errors{
	color: $blanco;
}
.panelGris{
    background: rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    display:none;
}
.panelGris.active, .modal.active{
	display:block;
}
.modal{
    position: fixed;
    width: 400px;
    height: auto;
    background-color: rgba(238,75,49,1);
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 5%;
    padding: 20px;
    display:none;
    .modal_title{
	    font-family: 'Lato', sans-serif;
	    color: $blanco;
	    padding-top: 0;
	    margin-bottom: 20px;
	    font-weight: bold;
    }
    .call_to_action{
    	width: 150px;
    	display:block;
    	margin:0 auto;
    }
} 
#closeMyModal{
	cursor:pointer;
	color: $gris_oscuro;
	font-family: cursive;
}
.default-box {
    background: #fff;
    padding: 28px;
}