html, body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.slider {
    width: 50%;
    margin: 100px auto;
}

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
    color: black;
}
@media #{$small-only}{
  .slick-next{
    display:none !important;
  }
  .slick-prev{
    display:none !important;
  }
}
.slide_theme{
	width: 800px;
	height: 400px !important;
	background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  @media #{$small-only}{
    height: 190px !important;
    background-size:100% !important;
  }
}
.call_to_slide{
    bottom: 25px;
    position: absolute;
    right: 88px;
    @media #{$small-only}{
      bottom: 0;
      margin: 0 auto;
      left:0;
      right:0;
      width: 100%;
    }
}

.call_to_individuales{
    bottom: 50px;
    position: absolute;
    right: 88px;
    @media #{$small-only}{
      bottom: 0;
      margin: 0 auto;
      left:0;
      right:0;
      width: 100%;
    }
}
.call_to_action{
    color:$naranja;
    font-weight: bold;
    border:1px solid $blanco;
    background: rgb(255,222,0);
    background: -moz-linear-gradient(top,  rgba(255,222,0,1) 0%, rgba(255,180,0,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(255,222,0,1) 0%,rgba(255,180,0,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,222,0,1) 0%,rgba(255,180,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffde00', endColorstr='#ffb400',GradientType=0 );
    &:hover{
      color:$naranja_hover;
      background: -moz-linear-gradient(top,  rgba(255,180,0,1) 0%, rgba(255,222,0,1) 100%);
      background: -webkit-linear-gradient(top,  rgba(255,180,0,1) 0%,rgba(255,222,0,1) 100%);
      background: linear-gradient(to bottom,  rgba(255,180,0,1) 0%,rgba(255,222,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffb400', endColorstr='#ffde00',GradientType=0 );
    }
}
.franja_productos{
  padding: 30px 0;
  display: inline-block;
    .product{
      .productos_lista{
        /*display:table-cell;
        vertical-align: middle;*/
        width:100%; 
        height:100px;
        text-align: center;
        img{
          max-width: 100%;
          width: 75%;
        }
      }
      a{
        font-family: 'Lato', sans-serif;
        font-style:italic;
        div{
          margin:0 auto;
        }
        &:hover{
          text-decoration: underline;
        }
      }
  }
}

.franja_video{
    background: url(../img/background_orange.jpg);
    background-position: center;
    background-size: cover;
    padding: 70px 0;
    color:$blanco;
    font-family: 'Lato', sans-serif;
    .home_video{
      margin-bottom: 20px; 
      iframe{
        max-width: 400px;
        width:100%;
        height:200px;
      }
    }
}

.franja_noticias{
  padding: 90px 0;
  font-family: 'Lato', sans-serif;
  .noticias_title{
     font-size: 25px;
     color: $naranja;
     margin-bottom:40px;
  }
  .noticias_slide{
    .slide_noticia{
      height:300px !important;
      div{
        .titulo_slide{
          font-size: 24px !important;
          @media #{$small-up} {
            font-size:24px;
          }
          @media #{$medium-up} {
            font-size:24px;
          }
          @media #{$large-up} {
            font-size:24px;
          }
        }
        .date{
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        span{
          a{
            font-style:italic;
            font-size:16px;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.franja_testimonios{
  background:$gris;
  padding: 60px 0;
  font-family: 'Lato', sans-serif;
  .testimonios_title{
      font-size:25px;
      margin-bottom: 40px;
      color:$naranja;
  }
  .btn_action_testimonios{
    margin-top:55px;
  }
  .testimonio{
      .img-circle{
        border-radius:500px;
        border: 5px solid $blanco;
        width:85px;
        height:85px;
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
      }
      span{
        vertical-align: middle;
      }
      .contenido_testimonio{
        padding:0 10px;
        font-size:15px;
        margin-top:15px;
      }
    }
}

.franja_mapa{
    background: url(../img/background_orange.jpg);
    background-position: center;
    background-size: cover;
    padding: 50px 0 0 0;
    color:$blanco;
    font-family: 'Lato', sans-serif;
    .mapa_title{
      font-size:25px;
      margin-bottom: 40px;
    }
    #map{
      width:100%;
      height: 600px;
    }
}
.buscador_sucursales{
  /*position:relative;*/
  position:absolute; 
  z-index:100;
  top:40%;
  .searchButton{
    position: absolute;
    padding: 5px;
    top: 1px;
    left: 31px;
    @media #{$small-only}{
      left: 23px;
    }
  }
  input[type=text]{
    padding-left:50px;
    padding-right:100px;
    margin-bottom: 0;
  }
  .buscar_btn{
    position: absolute;
    right: 31px;
    top: 1px;
    padding: 0.6em;
    @media #{$small-only}{
      right: 21px;
    }
  }
  #msgBox{
    display: inline-block;
    width: 390px;
    position: absolute;
  }
}
.ui-draggable, .ui-droppable {
  background-position: top;
}
.busqueda_sucursales_list{
  font-family: 'Lato', sans-serif;
  width: 100%;
  float:left;
  border:1px solid #ccc;
  margin-left:0;
  display:inline-block;
  li{
    list-style:none;
    padding: 15px 30px;
    width: 100%;
    background: rgba(0,0,0, 0.8);
    color:$blanco;
    cursor: pointer;
    &:hover{
      color:$blanco;
      background: rgba(62,62,62, 0.8);
    }
    .busqueda_nombre_sucursal{
      text-transform: uppercase;
      font-weight: bold;
    }
    .busqueda_telefono_sucursal{
      margin-top:10px;
    }
  } 
}