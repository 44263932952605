.franja_noticia_individual{
 	font-family: 'Lato', sans-serif;	
	padding: 70px 0 70px 0;
	.header_notice{
		margin-bottom: 30px;
		.titulo_noticia{
			font-size: 30px;
		}
		.date{
			font-size: 12px;
		    font-weight: bold;
		    margin-bottom: 10px;
		}
	}
	.body_notice{
		margin-top:30px;
	}
}