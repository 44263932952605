.c_slide{
	display: block;
	overflow:hidden;
	width: 100%;
	position:relative;
	ul{
		display:inline-block;
		margin:0;
		transform: translateX(0px);
		transition:all 0.3s ease-in;
		-webkit-transition:all 0.3s ease-in;
		-moz-transition:all 0.3s ease-in;
		-o-transition:all 0.3s ease-in;
		.slide_programas{
			list-style: none;
			width: 100%;
			float: left;
			.titulo_slide{
				font-size: 24px !important;
				color:$naranja;
				text-transform: uppercase;
				font-family: 'Lato', sans-serif;
				@media #{$small-only}{
					margin-top:10px;
				}
			}
		}
	}
}

.customDots{
	margin-bottom:50px;
	cursor:pointer;
	div{
		text-align: center;
	}
}

.prev{
	background: url("../img/left_arrow_gray.png");
	background-repeat: no-repeat;
	width: 40px;
	height:60px;
	float:left;
	position: absolute;
	top: 40%; 
	left: -50px;
	@media #{$small-only}{
		left:-25px;
		width:27px;
		background-size: 100%;
	}
}

.next{
	background: url("../img/right_arrow_gray.png");
	background-repeat: no-repeat;
	width: 40px;
	height:60px;
	float:right;
	position: absolute;
	top: 40%; 
	right:-50px;
	@media #{$small-only}{
		right:-25px;
		width:27px;
		background-size: 100%;
	}
}