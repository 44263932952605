@import url(https://fonts.googleapis.com/css?family=Lato:400,700,400italic);
@import url(https://fonts.googleapis.com/css?family=Alef:400,700);

$naranja: #ee4b31;
$naranja_hover: #f03618;
$gris: #ebebeb;
$gris_oscuro: #6f6f70;
$blanco: #FFFFFF;
$negro: #000000;
$amarillo: #FFD200;
body{
   font-family: 'Lato', sans-serif;
}

header{
  margin-top:15px;
  margin-bottom: 15px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color:$gris_oscuro;
  .franja-telefono{
    font-family: 'Alef', sans-serif;
    font-weight: bold;
    @media #{$small-only}{
      span:first-child{
        display:inline-block;
        width: 100%;
        text-align: center;
      }
      a{
        display: inline-block;
        float: left;
      }
    }
  }
  .title-bar{
    display:inline-block;
     background-color: $naranja;
    width:100%;
  }
  .dropdown{
    li{
      float: left;
      @media #{$small-only}{
        width: 100%; 
      }
    }
  }
}
.txt-orange{
  color:$naranja;
}
.sprite_redes{
  display: inline-block;
  background-size: 83px 22px;
  background-repeat:no-repeat !important;
}
.rWhite{
   background: url(../img/sprite_social_white.png);
   background-repeat: no-repeat;
   float: right;
}
.rOrange{
   background: url(../img/sprite_social.png);
   background-repeat: no-repeat;
   float: right;
}
.rFacebook{
  background-position: 13px 0;
  width: 33px;
  height:22px;
}
.rYoutube{
    background-position: -19px 0;
    width: 33px;
    height: 22px;
}
.rTwitter{
    background-position: -55px 0;
    width: 33px;
    height: 22px;
}

.top-bar, .top-bar ul{
  background-color: #fff;
  li{
    border-bottom: 5px solid $blanco;
    text-transform: uppercase;
    font-size:16px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    &:hover{
      border-bottom: 5px solid $naranja;
    }
  }
}
li.is-submenu-item.is-dropdown-submenu-item {
    font-size: 13px;
}
.dropdown.menu>li.is-dropdown-submenu-parent>a::after{
    border: inset 0px;
     /* border-color:$naranja transparent transparent;*/
}
a{
    color:$gris_oscuro;
    &:hover{
      color:$naranja_hover;
    }
    &:focus{
     color:$naranja_hover; 
    }
}  
.menu.vertical{
  background-color:$naranja;
  li{
      border-bottom:5px solid $naranja;
    a{
      color:$blanco;
    }
    &:hover{
      background-color:$naranja_hover;
    }
  }
}
.menu{
    li.active{
    border-bottom:5px solid $naranja;
    a{
      color:$naranja_hover;
    }
  }
}
.menu.vertical{
  li.active{
    background-color:#fff;
    border-bottom:0px solid $naranja;
    a{
      color:$naranja_hover;
    }
  }
}
.is-submenu{
      font-size: 14px;
}
.menu .active>a{
  background-color:transparent;
  color: $gris_oscuro;
}