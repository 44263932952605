.franja_conocenos_video{
	background: url(../img/background_orange.jpg);
    background-position: center;
    background-size: cover;
    padding: 50px 0 50px 0;
    color:$blanco;
    font-family: 'Lato', sans-serif;
    iframe{
    	width:560px; 
    	height:315px;
      @media #{$small-only}{
        width: 100%;
      }
    }
}
.franja_nuestros_productos
{
	 padding: 50px 0 50px 0;
   font-family: 'Lato', sans-serif;
    .nuestros_productos_title{
    	font-size:25px;
      	margin-bottom: 40px;
      	color:$naranja;
    }

.nuestos_productos_slide{
    .slide_nuestros_productos{
      height:300px !important;
      div{
      		height:100%;
      	img{
      		height:100%;
      		width: auto;
      	}
        .titulo_slide{
          font-size: 24px !important;
          color:$naranja;
          @media #{$small-up} {
            font-size:24px;
          }
          @media #{$medium-up} {
            font-size:24px;
          }
          @media #{$large-up} {
            font-size:24px;
          }
        }
        .date{
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        span{
          a{
            font-style:italic;
            font-size:16px;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.franja_acerca_de{
	background: url(../img/background_orange.jpg);
    background-position: center;
    background-size: cover;
    padding: 50px 0 50px 0;
    color:$blanco;
    font-family: 'Lato', sans-serif;
    .acerca_de_title{
    	font-size:25px;
      	margin-bottom: 10px;
    }
    .contenido_acerca_de{
    	img{
    		float: left;
    		width: 180px;
        @media #{$small-only}{
          width: 100%;
          text-align: center;
        }
    	}
    }
}

.franja_nuestros_programas{
	padding: 50px 0 50px 0;
  font-family: 'Lato', sans-serif;
	.nuestros_programas_title{
    font-family: 'Lato', sans-serif;
		font-size:25px;
    margin-bottom: 40px;
    color: $naranja;
	}
.nuestos_programas_slide{
    .slide_nuestros_programas{	
    	width: 100% !important;
      	height:300px !important;
        font-family: 'Lato', sans-serif;
	    div{
      		height:100%;
      	img{
      		height:100%;
      		width: auto;
	    }
        .titulo_slide{
          font-size: 24px !important;
          color:$naranja;
          font-family: 'Lato', sans-serif;
          @media #{$small-up} {
            font-size:24px;
          }
          @media #{$medium-up} {
            font-size:24px;
          }
          @media #{$large-up} {
            font-size:24px;
          }
        }
      }
    }
  }
}