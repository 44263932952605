.nuestos_productos_slide{
	.slide_nuestros_prodructos{
     font-family: 'Lato', sans-serif;
		.titulo_slide{
           font-family: 'Lato', sans-serif;
          font-size: 24px !important;
          color:$naranja;
          @media #{$small-up} {
            font-size:24px;
          }
          @media #{$medium-up} {
            font-size:24px;
          }
          @media #{$large-up} {
            font-size:24px;
          }
        }
	}
}